<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editTask">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">{{ $t("DetallesTarea") }}</h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group :label="$t('Nombre')" label-for="task-name">
                <b-form-input
                  v-model="task.name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6" v-if="event && event.responsable_roles_object.length">
            <validation-provider
              #default="{ errors }"
              :name="$t('Usuarios')"
              rules="required"
            >
              <b-form-group
                :label="$t('Usuarios')"
                label-for="task-responsable"
              >
                <BaseSelect
                  v-model="users"
                  :searchable="true"
                  :clearable="true"
                  :multiple="true"
                  :httpBody="{  
                    id_company: currentCompany.id,
                    id_client: currentClient.id,
                    roles: rolesResponsable,
                  }"      
                  :resource="'/users'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- <b-col sm="6" v-if="event && event.user_roles_object.length">
            <validation-provider
              #default="{ errors }"
              :name="$t('Usuarios')"
              rules="required"
            >
              <b-form-group :label="$t('Usuarios')" label-for="task-users"> -->
                <!-- <v-select
                  v-model="users"
                  label="name"
                  multiple
                  :filterable="false"
                  :searchable="false"
                  :options="selectUsers"
                  :placeholder="$t('Usuarios')"
                /> -->
                <!-- <BaseSelect
                  v-model="users"
                  :searchable="true"
                  :multiple="true"
                  :httpBody="{  
                    id_company: currentCompany.id,
                    id_client: currentClient.id,
                  }"      
                  :resource="'/users'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->
          <!-- <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Revision')"
              rules="required"
            >
              <b-form-group :label="$t('Revision')" label-for="task-event">
                <v-select
                  v-model="event"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectEvents"
                  @input="onChangeEvent"
                  :placeholder="$t('Revision')"
                  :disabled="true"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Prioridad')"
            >
              <b-form-group :label="$t('Prioridad')" label-for="task-priority">
                <v-select
                  v-model="priority"
                  label="label"
                  :filterable="false"
                  :searchable="false"
                  :options="selectPriorities"
                  :placeholder="$t('Prioridad')"
                >
                  <template slot="option" slot-scope="option">
                    {{ $t("priority." + option.label) }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ $t("priority." + option.label) }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Estado')"
              rules="required"
            >
              <b-form-group :label="$t('Estado')" label-for="task-status">
                <v-select
                  v-model="task.status"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectStatus"
                  :placeholder="$t('Estado')"
                >
                  <template slot="option" slot-scope="option">
                    {{ $t("status.tasks." + option.alias) }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ $t("status.tasks." + option.alias) }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--<b-col sm="6" class="d-none">
            <b-form-group
              :label="$t('Proyecto')"
              label-for="task-project"
              v-if="event && event.alias === 'ot'"
            >
              <v-select
                v-model="task.project"
                label="name"
                :filterable="false"
                :searchable="false"
                :options="selectProjects"
                :placeholder="$t('Proyecto')"
              />
            </b-form-group>
          </b-col>-->
        </b-row>
        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('FechaInicio')"
              rules="required"
            >
              <b-form-group
                :label="$t('FechaInicio')"
                label-for="task-fechaini"
              >
                <flat-pickr
                  v-model="date_ini"
                  :config="{ enableTime: true }"
                  name="date_ini"
                  class="form-control"
                  :placeholder="$t('dd/mm/YYYY')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('FechaFin')" label-for="task-fechafin">
              <flat-pickr
                v-model="date_fin"
                :config="{ enableTime: true }"
                name="date_fin"
                class="form-control"
                :placeholder="$t('dd/mm/YYYY')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Actions')"
              rules="required"
            >
              <b-form-group :label="$t('Actions')" label-for="form-clients">
                <v-select
                  v-model="form"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectForms"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('TaskRecurrence')"
              label-for="task-recurrence"
            >
              <b-form-checkbox
                id="recurrence"
                v-model="recurrence"
                name="check-button-recurrence"
                switch
                class="mt-1"
                inline
              />
            </b-form-group>
          </b-col>
          <b-col v-if="recurrence" sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('ReviewFrecuency')"
              :rules="reviewFrecuencyRequired"
            >
              <b-form-group
                :label="$t('ReviewFrecuency')"
                label-for="product-subgrupo"
              >
                <v-select
                  v-model="frequency_type"
                  :filterable="true"
                  :searchable="true"
                  :options="selectRevisiones"
                  :placeholder="$t('ReviewFrecuency')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="$t('Descripcion')"
              label-for="task-description"
            >
              <quill-editor
                v-model="task.description"
                :options="editorOption"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group :label="$t('Documentos')" label-for="task-documents">
              <ImageDropzone
                ref="documents"
                :files="files"
                maxFiles="10"
                v-if="taskPrepare"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!--   <p class="font-weight-bolder" >{{ $t('Productos') }}</p> -->
            <b-row>
              <!--   <draggable-list-products /> -->
              <draggable-list-assets
                :event="event.alias"
                :productsSelected="productsSet"
              />
              <b-col cols="6">
                <b-list-group class="cursor-move box-drag-products-parent">
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-if="typesAvailable['assets']"
                  >
                    <h5 class="font-weight-bolder mb-0">{{ $t("Equipos") }}</h5>
                  </b-list-group-item>
                  <draggable
                    :list="productsSet"
                    :group="{ name: 'products' }"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item
                      v-if="p.type == 'asset'"
                      v-for="(p, index) in productsSet"
                      :key="index"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>{{ p.name }}</span>
                      <b-button
                        variant="outline-danger"
                        @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                  </draggable>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-if="typesAvailable['epis']"
                  >
                    <h5 class="font-weight-bolder mb-0">{{ $t("EPIs") }}</h5>
                  </b-list-group-item>
                  <draggable
                    :list="productsSet"
                    :group="{ name: 'products' }"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item
                      v-if="p.type == 'epi'"
                      v-for="(p, index) in productsSet"
                      :key="index"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>{{ p.name }}</span>
                      <b-button
                        variant="outline-danger"
                        @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                  </draggable>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-if="typesAvailable['installations']"
                  >
                    <h5 class="font-weight-bolder mb-0">{{ $t("Instalaciones") }}</h5>
                  </b-list-group-item>
                  <draggable
                    :list="productsSet"
                    :group="{ name: 'products' }"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item
                      v-if="p.type == 'installation'"
                      v-for="(p, index) in productsSet"
                      :key="index"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>{{ p.name }}</span>
                      <b-button
                        variant="outline-danger"
                        @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                  </draggable>
                  <div class="box-drag-products">
                    <feather-icon icon="UploadIcon" size="16" />
                    <p class="m-0">{{ $t("ArrastraProductos") }}</p>
                  </div>
                </b-list-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" v-if="actions.length" class="d-none">
            <p>{{ $t("ListadoAcciones") }}</p>
            <draggable
              :list="actions"
              :group="{ name: 'tags' }"
              class="mb-1 cursor-move"
              @add="handleChangeActions"
              @end="handleChangeActions"
              @remove="handleChangeActions"
              @move="handleChangeActions"
            >
              <b-list-group-item
                v-for="(act, index) in actions"
                :key="index"
                tag="li"
              >
                <div>
                  <div class="ml-25">
                    <form-task
                      v-if="act.alias == 'form' && act.config"
                      :info="act"
                    />
                    <div v-else>
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ act.name }}
                      </b-card-text>
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ $t("Enviar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BListGroupItem,
  BListGroup,
  BFormRadioGroup,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {
  FlatPickrToTimestampWithTime,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import flatPickr from "vue-flatpickr-component";
import { config } from "@/shared/app.config";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone";
import FormTask from "@/views/events/actions/FormTask.vue";
/* import DraggableListProducts from '@/views/products/DraggableListProducts' */
import DraggableListAssets from "@/views/assets/DraggableListAssets";
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  components: {
    BFormRadioGroup,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BListGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    draggable,
    BListGroupItem,
    vSelect,
    flatPickr,
    ImageDropzone,
    FormTask,
    BaseSelect,
    /* DraggableListProducts, */
    DraggableListAssets,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notify: null,
      optionsNotify: [
        { text: "Correo electrónico", value: "first" },
        { text: "SMS", value: "second" },
        { text: "Push móvil", value: "third" },
      ],
      required,
      email,
      date_ini: "",
      date_fin: "",
      frequency_type: "",
      actions: [],
      form: "",
      recurrence: 0,
      selectPriorities: config.priorities,
      responsable: "",
      event: "",
      trashZone: [],
      files: [],
      taskPrepare: false,
      productsSet: [],
      users: [],
      priority: "",
      selectRevisiones: [
        { value: "weekly", label: this.$t("review_frequency.weekly") },
        { value: "monthly", label: this.$t("review_frequency.monthly") },
        { value: "quarterly", label: this.$t("review_frequency.quarterly") },
        { value: "biannual", label: this.$t("review_frequency.biannual") },
        { value: "yearly", label: this.$t("review_frequency.yearly") },
      ],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      task: "tasks/getTask",
      localeDatePicker: "languages/getLocaleDatePicker",
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
      selectActions: "actions/getSelectActions",
      // selectUsers: "users/getSelectUsers",
      selectEvents: "events/getItems",
      selectStatus: "tasks/getSelectStatus",
      //selectProjects: "projects/getSelectProjects",
      selectedEvent: "events/getEvent",
      selectForms: "forms/getItems",
    }),
    typesAvailable() {
      const types = JSON.parse(localStorage.getItem('typesAvailables'))
      return types
    },
    reviewFrecuencyRequired() {
      return this.recurrence ? "required" : "";
    },
  },
  watch: {
    selectedEvent() {
      if (this.selectedEvent) {
        this.actions = this.selectedEvent.actions;
      }
    },
  },
  methods: {
    ...mapActions({
      edit: "tasks/edit",
      getTask: "tasks/getTask",
      getSelectActions: "actions/getSelectActions",
      // getSelectUsers: "users/selectUsers",
      getSelectEvents: "events/getList",
      getSelectStatus: "tasks/getSelectStatus",
      //getSelectProjects: "projects/getSelectProjects",
      getEvent: "events/getEvent",
      getSelectForms: "forms/getList",
    }),
    handleSubmit() {
      this.$refs.editTask.validate().then((success) => {
        if (success) {
          const { id } = this.task;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, task: formData });
          }
        }
      });
    },
    async setData() {
      if (this.task.objects) {
        this.task.objects.forEach((element) => {
          element.object.type = element.type;
          element.object.order = element.order;
          this.productsSet.push(element.object);
        });
      }
      if (this.task.priority && this.task.priority !== '') {
        this.selectPriorities.forEach((element) => {
          if (element.value === this.task.priority) {
            this.priority = element;
          }
        });
      }
      if (this.task.date_ini) {
        this.date_ini = TimestampToFlatPickrWithTime(this.task.date_ini);
      }
      if (this.task.date_fin && this.task.date_fin !== "null") {
        this.date_fin = TimestampToFlatPickrWithTime(this.task.date_fin);
      }
      if (this.task.actions) {
        this.actions = this.task.actions;
      }
      if (this.task.responsible_user) {
        this.responsable = this.task.responsible_user;
      }
      if (this.task.users) {
        this.users = this.task.users;
      }
      if (this.task.event) {
        this.event = this.task.event;
      }
      if (this.task.documents.length > 0) {
        const aux = this;
        this.task.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/tasks/delete_doc/${aux.task.id}/${element.id}`,
          };
          this.files.push(file);
        });
      }
      if (this.task.form_id) {
        this.form = this.selectForms?.find(
          ({ id }) => id === this.task.form_id
        );
      }
      if (this.task.frequency_type) {
        this.frequency_type = this.selectRevisiones.find(
          ({ value }) => this.task.frequency_type === value
        );
      }
      this.recurrence = this.task.recurrence === 1;
      this.taskPrepare = true;
    },
    onChangeEvent() {
      if (this.event && this.event.id) {
        this.getEvent(this.event.id);
      }
    },
    createFormData() {
      const data = this.$refs.documents.getFormData("documents");

      data.append("date_ini", FlatPickrToTimestampWithTime(this.date_ini));

      if (this.date_fin) {
        data.append("date_fin", FlatPickrToTimestampWithTime(this.date_fin));
      } else {
        data.append("date_fin", null);
      }

      if (this.actions.length > 0) {
        this.actions.forEach((element) => {
          data.append("actions[]", JSON.stringify(element));
        });
      } else {
        data.append("actions", "");
      }

      if (this.productsSet.length > 0) {
        this.productsSet.forEach((element) => {
          if (element.type === "epi") {
            data.append("epis[]", JSON.stringify(element));
          }
          if (element.type === "asset") {
            data.append("assets[]", JSON.stringify(element));
          }
          if (element.type === "installation") {
            data.append("installations[]", JSON.stringify(element));
          }
        });
      } else {
        data.append("products", "");
        data.append("assets", "");
        data.append("sets", "");
      }
      if (this.users) {
        this.users.forEach((user) => {
          data.append("users[]", user.id);
        });
      } else {
        data.append("users", "");
      }
      /*if (this.event && this.event.alias === "ot" && this.task.project) {
        data.append("project_id", this.task.project.id);
      }*/

      data.append("priority", this.priority?.value);
      data.append("name", this.task.name);
      data.append("status_task_id", this.task.status.id);
      // data.append("responsible_user_id", this.responsable.id);
      data.append("event_id", this.event.id);
      data.append("form_id", this.form.id);
      data.append("description", this.task.description);

      data.append("recurrence", this.recurrence ? 1 : 0);
      if (this.recurrence) {
        data.append("frequency_type", this.frequency_type.value);
      }
      return data;
    },
    handleChangeActions() {
      const actionsAux = [];
      this.actions.forEach((element, index) => {
        let config = {};
        if (element.config) {
          config = element.config;
        }
        actionsAux.push({
          order: index + 1,
          id: element.id,
          name: element.name,
          alias: element.alias,
          config,
        });
      });
      this.actions = actionsAux;
    },
    handleChange() {
      const productsSetAux = [];
      this.productsSet.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        };
      });
      this.productsSet = productsSetAux;
    },
    removeProduct(index) {
      this.productsSet.splice(index, 1);
    },
  },
  async mounted() {
    this.$store.commit("app/loading", true, { root: true });
    try {
      const promises = [
        this.getTask(this.$route.params.id),
        this.getSelectEvents(),
        // this.getSelectUsers({
        //   company: this.currentCompany.id,
        //   client: this.currentClient.id,
        // }),
        this.getSelectForms({
          page: 1,
          per_page: 99999,
          search: "",
          type: "review",
        }),
        this.getSelectActions(),
        this.getSelectStatus(),
        //this.getSelectProjects({ client: this.currentClient.id }),
      ];

      await Promise.all(promises);

      this.setData();
    } finally {
      this.$store.commit("app/loading", false, { root: true });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
